import React, { useState } from 'react';
import { Routes, Route, Link } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { AppBar, Toolbar, Typography, Button, IconButton, Menu, MenuItem, Box } from '@mui/material';
import Home from './pages/Home';
import Leistungen from './pages/Leistungen';
import Praxis from './pages/Praxis';
import ÜberMich from './pages/ÜberMich';
import Kontakt from './pages/Kontakt';
import './index.css';
import logo from './assets/Logo.jpg';
import Footer from './pages/Footer';
import Impressum from './pages/Impressum';
import Datenschutzerklaerung from './pages/Datenschutzerklaerung';

// Create a custom theme with MuiCard background color override
const theme = createTheme({
  palette: {
    primary: {
      main: '#4CAF50',
    },
    secondary: {
      main: '#FF5722',
    },
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    h1: {
      fontWeight: 600,
    },
    h4: {
      fontWeight: 500,
    },
    button: {
      textTransform: 'none',
    },
  },
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          backgroundColor: '#fefedf', // Apply global background color for all Card components
        },
      },
    },
  },
});

function App() {
  const [anchorEl, setAnchorEl] = useState(null);
  const isMenuOpen = Boolean(anchorEl);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
          width: '100%',
          overflowX: 'hidden',
        }}
      >
        <AppBar
          position="fixed"
          sx={{
            width: '100%',
            top: 0,
          }}
        >
          {/* Centering Box for Toolbar content */}
          <Box
            sx={{
              maxWidth: '1200px',     // Set maximum width for centered alignment
              mx: 'auto',              // Center horizontally
              width: '100%',
            }}
          >
            <Toolbar sx={{ justifyContent: 'space-between' }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <img
                  src={logo}
                  alt="Heilpraktiker Logo"
                  style={{ width: '40px', height: '40px', marginRight: '16px' }}
                />
                <Typography variant="h6">Heilpraktiker Sylwia Spiller</Typography>
              </Box>

              <IconButton
                size="large"
                edge="end"
                color="inherit"
                aria-label="menu"
                onClick={handleMenuOpen}
                sx={{
                  display: { xs: 'flex', md: 'none' },
                  ml: 'auto',
                }}
              >
                &#9776;
              </IconButton>

              <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                <Button color="inherit" component={Link} to="/">Home</Button>
                <Button color="inherit" component={Link} to="/leistungen">Leistungen</Button>
                <Button color="inherit" component={Link} to="/praxis">Praxis</Button>
                <Button color="inherit" component={Link} to="/über-mich">Über mich</Button>
                <Button color="inherit" component={Link} to="/kontakt">Kontakt</Button>
              </Box>
            </Toolbar>
          </Box>

          <Menu
            anchorEl={anchorEl}
            open={isMenuOpen}
            onClose={handleMenuClose}
            sx={{
              '& .MuiPaper-root': {
                width: '200px',
              },
            }}
          >
            <MenuItem component={Link} to="/" onClick={handleMenuClose}>Home</MenuItem>
            <MenuItem component={Link} to="/leistungen" onClick={handleMenuClose}>Leistungen</MenuItem>
            <MenuItem component={Link} to="/praxis" onClick={handleMenuClose}>Praxis</MenuItem>
            <MenuItem component={Link} to="/über-mich" onClick={handleMenuClose}>Über mich</MenuItem>
            <MenuItem component={Link} to="/kontakt" onClick={handleMenuClose}>Kontakt</MenuItem>
          </Menu>
        </AppBar>

        {/* Padding-top to prevent content from being hidden behind AppBar */}
        <Box sx={{ flex: 1, pt: { xs: 8, md: 10 } }}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/leistungen" element={<Leistungen />} />
            <Route path="/praxis" element={<Praxis />} />
            <Route path="/über-mich" element={<ÜberMich />} />
            <Route path="/kontakt" element={<Kontakt />} />
            <Route path="/impressum" element={<Impressum />} />
            <Route path="/datenschutzerklarung" element={<Datenschutzerklaerung />} />
          </Routes>
        </Box>

        <Footer />
      </Box>
    </ThemeProvider>
  );
}

export default App;
