import React from 'react';
import { Container, Typography, Button, Card, CardContent, Box } from '@mui/material';
import { Link } from 'react-router-dom'; // Import Link
import banner from "../assets/Logo.jpg";

function Home() {
  return (
    <Container
      className="container"
      style={{
        textAlign: 'center',
        padding: '50px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center' // Center content vertically, but allows for scrolling

      }}
    >
      {/* Top Content */}
      <Box>
        <Typography variant="h4" gutterBottom>
          Willkommen in meiner Praxis für Naturheilkunde
        </Typography>
        <Typography variant="body1" style={{ margin: '20px 0', fontSize: '1.2rem' }}>
          Entdecken Sie die heilende Kraft der Natur und fördern Sie Ihr Wohlbefinden durch ganzheitliche Ansätze.
        </Typography>

        {/* Feld für das größere Bild */}
        <Box
          component="img"
          src={banner} // Fügen Sie hier den Pfad zu Ihrem Bild ein
          alt="Heilpraxis"
          sx={{
            width: '100%', // Bild passt sich der Breite des Containers an
            maxWidth: '600px', // Maximale Breite des Bildes
            borderRadius: '15px',
            boxShadow: '0 2px 10px rgba(0,0,0,0.2)',
            margin: '20px 0', // Abstand über und unter dem Bild
          }}
        />
      </Box>

      {/* Card Content */}
      <Card style={{ marginTop: '20px', borderRadius: '15px', boxShadow: '0 2px 10px rgba(0,0,0,0.2)' }}>
        <CardContent style={{ padding: '20px' }}>
          <Typography variant="h5">Meine Philosophie</Typography>
          <Typography variant="body2">
            Ich glaube an die Verbindung von Körper, Geist und Seele. Meine Behandlungsmethoden sind darauf ausgerichtet, die Selbstheilungskraft zu aktivieren und das Wohlbefinden zu fördern. Ich begleite Sie auf dem Weg zu besserer Gesundheit und damit zu einer besseren Lebensqualität.
          </Typography>
        </CardContent>
      </Card>

      {/* Button zum Kontakt, placed at the bottom */}
      <Link to="/kontakt" style={{ textDecoration: 'none', marginTop: '20px' }}>
        <Button variant="contained" color="primary">
          Jetzt Termin vereinbaren
        </Button>
      </Link>
    </Container>
  );
}

export default Home;